<template>
  <div>
    <div class="container-xxl">
      <div class="row">
        <div class="col">
          <NotificationList class="my-3"></NotificationList>

          <SubmitButton
            @click="markAsRead"
            v-if="notificationHasUnreadItems"
            :text="`Mark all as 'Read'`"
            :textBusy="'Please wait...'"
            :isLoading="isLoading"
            :disabled="isLoading"
            class="btn btn-primary mb-3"
          ></SubmitButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Notification',
  components: {
    SubmitButton: () => import('@/components/button/SubmitButton'),
    NotificationList: () => import('@/controllers/notification/NotificationList'),
  },
  computed: {
    ...mapState('notification', ['notificationItems']),
    ...mapGetters('notification', ['notificationHasUnreadItems']),
  },
  methods: {
    async markAsRead() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('notification/markAllNotificationsAsRead');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>
